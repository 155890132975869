import React, {useEffect, useState} from 'react';
import Modal from "../UI/Modal";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {OrderItemType} from "../../models/OrderModels";

const AddItem = (props: {
    orderId: number,
    orderDesiredAt: string,
    modal: boolean,
    closeModal: () => void,
    AddItemF: (Item: any) => void
}) => {
    const [Step, SetStep] = useState(1)

    const EmptyItem = {
        order_id: props.orderId,
        desired_at: props.orderDesiredAt,
        comment: null,
        group: null,
        groupId: null,
        type: null,
        material: null,
        color: null,
        sex: null,
        wear: null
    }

    const [Item, SetItem] = useState<any>(EmptyItem)

    const ig = useTypedSelector(state => state.item_groups)
    const it = useTypedSelector(state => state.item_types)
    const im = useTypedSelector(state => state.itemRefs)

    async function AddItem() {
        if (Item.wear != null) {
            props.AddItemF(Item)
            SetItem(EmptyItem)
            SetStep(1)
        }
    }

    useEffect(function () {
        AddItem()
    }, [Item])

    return (
        <div>
            <Modal id="add_item" title="Добавить изделие"  onClose={() => {
                SetItem(EmptyItem)
                SetStep(1)
                props.closeModal()
            }} show={props.modal} addClass="modal-lg">
                <h4>Шаг <span>{Step}</span>/6</h4>
                {Step === 1 &&
                    <div className="row service_list">
                        {ig.item_groups.map((el) =>
                            <div className="col-lg-4 col-md-6 mb-4" key={el.id}>
                                <div className="card serviceCard h-100" onClick={() => {
                                    SetItem({...Item, group: el.name, groupId: el.id})
                                    SetStep(2)
                                }} style={{backgroundImage: 'linear-gradient(310deg, rgb(43, 42, 41), rgb(102, 102, 102))', color: 'rgb(204, 204, 204)'}}>
                                    <div className="card-body p-3"><h6 style={{color: 'rgb(255, 255, 255)'}}>{el.name}</h6></div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                {Step === 2 &&
                    <div className="row service_list">
                        {it.item_types[Item.groupId].map((el: any) =>
                            <div className="col-lg-4 col-md-6 mb-4" key={el.id}>
                                <div className="card serviceCard h-100" onClick={() => {
                                    SetItem({...Item, type: el.name})
                                    SetStep(3)
                                }} style={{backgroundImage: 'linear-gradient(310deg, rgb(43, 42, 41), rgb(102, 102, 102))', color: 'rgb(204, 204, 204)'}}>
                                    <div className="card-body p-3"><h6 style={{color: 'rgb(255, 255, 255)'}}>{el.name}</h6></div>
                                </div>
                            </div>
                        )}
                    </div>
                }
                {Step === 3 &&
                <div className="row service_list">
                    {im.materials.map((el: any) =>
                        <div className="col-lg-4 col-md-6 mb-4" key={el.id}>
                            <div className="card serviceCard h-100" onClick={() => {
                                SetItem({...Item, material: el.name})
                                SetStep(4)
                            }} style={{background: 'url(https://endlessmind.space' + el.bgimg + ')', backgroundSize: 'cover', color: 'rgb(204, 204, 204)'}}>
                                <div className="card-body p-3"><h6 style={{color: 'rgb(255, 255, 255)'}}>{el.name}</h6></div>
                            </div>
                        </div>
                    )}
                </div>
                }
                {Step === 4 &&
                <div className="row service_list">
                    {im.color.map((el: any) =>
                        <div className="col-lg-4 col-md-6 mb-4" key={el.id}>
                            <div className="card serviceCard h-100" onClick={() => {
                                SetItem({...Item, color: el.name})
                                SetStep(5)
                            }} style={{background: el.bgc, backgroundSize: 'cover', color: 'rgb(204, 204, 204)'}}>
                                <div className="card-body p-3 text-center"><h6 className="px-2" style={{margin: '0 auto', width: 'fit-content', backgroundColor: '#000', color: 'rgb(255, 255, 255)'}}>{el.name}</h6></div>
                            </div>
                        </div>
                    )}
                </div>
                }
                {Step === 5 &&
                <div className="row service_list">
                    {im.sex.map((el: any) =>
                        <div className="col-lg-4 col-md-6 mb-4" key={el.id}>
                            <div className="card serviceCard h-100" onClick={() => {
                                SetItem({...Item, sex: el.name})
                                SetStep(6)
                            }} style={{backgroundImage: 'linear-gradient(310deg, rgb(43, 42, 41), rgb(102, 102, 102))', color: 'rgb(204, 204, 204)'}}>
                                <div className="card-body p-3"><h6 style={{color: 'rgb(255, 255, 255)'}}>{el.name}</h6></div>
                            </div>
                        </div>
                    )}
                </div>
                }
                {Step === 6 &&
                <div className="row service_list">
                    {im.wears.map((el: any) =>
                        <div className="col-lg-4 col-md-6 mb-4" key={el.id}>
                            <div className="card serviceCard h-100" onClick={() => {
                                SetItem({...Item, wear: el.name})
                                props.closeModal()
                            }} style={{backgroundImage: 'linear-gradient(310deg, rgb(43, 42, 41), rgb(102, 102, 102))', color: 'rgb(204, 204, 204)'}}>
                                <div className="card-body p-3"><h6 style={{color: 'rgb(255, 255, 255)'}}>{el.name}</h6></div>
                            </div>
                        </div>
                    )}
                </div>
                }
            </Modal>
        </div>
    );
};

export default AddItem;