import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store";
import './css/App.css'
import axios from "axios";


// axios.defaults.baseURL = 'https://endlessmind.space/api/';
axios.defaults.baseURL = 'https://endlessmind.space/api_royalgloss/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);