import React, {useEffect, useState} from 'react';
import OrderTR from "../components/Order/OrderTR";
import {useTitle} from "../hooks/useTitle";
import {useNavigate} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import OrderRAPI from "../API/OrderRAPI";
import ClientRAPI from "../API/ClientRAPI";
import Modal from "../components/UI/Modal";

const Clients = () => {

    useTitle('Клиенты')
    const navigate = useNavigate();

    const [search, setSearch] = useState('')

    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({
        cur: 1,
        max: 10
    })

    const [fetchData, isLoading, errorData] = useFetching(async () => {
        const res = await ClientRAPI.search(search, page)
        if (res.list.length === 1) {
            navigate('/clients/' + res.list[0].id)
        } else {
            setOrders(res.list)
            setPagination(res.pagination)
        }
    })

    const searchInput = async (event) => {
        if (event.key === "Enter") {
            setPage(1)
            setSearch(event.target.value)
        }
    }
    const rows = [];
    for (let i = 1; i <= pagination.max; i++) {
        if (Math.abs(pagination.cur - i) <= 5 || i == pagination.max || i == 1) {
            if (Math.abs(pagination.cur - i) == 5) {
                rows.push(<li><a>...</a></li>);
            } else {
                rows.push(<li className={i == page ? 'active cp' : ' cp'} onClick={() => setPage(i)}><a>{i}</a></li>);
            }
        }
    }
    useEffect(() => {
        fetchData()
    }, [search, page])

    const [modalRating, setModalRating] = useState(false)
    const [rating, setRating] = useState({})
    const [ratingYear, setRatingYear] = useState('total')

    async function fetchRating() {
        const res = await ClientRAPI.rating()
        setRating(res)
    }

    useEffect(() => {
        fetchRating()
    }, [])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="d-sm-flex justify-content-start">
                    <div className="d-flex">
                        <input type="text" className="form-control" placeholder="Поиск по клиентам" style={{height: '41px'}} onKeyPress={(e) => searchInput(e)}/>
                    </div>
                    <div className="d-flex">
                        <button className="btn bg-gradient-primary ms-3" onClick={() => setModalRating(true)}>Рейтинг</button>
                    </div>
                    <div className="dataTable-wrapper ms-auto">
                        <div className="dataTable-bottom">
                            <nav className="dataTable-pagination">
                                <ul className="dataTable-pagination-list">
                                    {rows}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card dataTable-wrapper">
                    <div className="table-responsive">
                        <table className="table align-items-center mb-0 text-center">
                            <thead>
                            <tr>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Телфон</th>
                                <th className="text-start text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">ФИО</th>
                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Скидка</th>
                            </tr>
                            </thead>
                            <tbody>
                            {orders.length > 0 && orders.map((el) =>
                                <tr onClick={() => navigate('/clients/' + el.id)}>
                                    <td>{el.phone}</td>
                                    <td className="text-start">{el.last_name}&nbsp;{el.name}&nbsp;{el.second_name} <span className="badge bg-gradient-warning">{el.type}</span></td>
                                    <td>{el.discount}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className="dataTable-bottom mt-4">
                        <nav className="dataTable-pagination">
                            <ul className="dataTable-pagination-list">
                                {rows}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <Modal title="Рейтинг клиентов" show={modalRating} onClose={() => setModalRating(false)} addClass="modal-lg">
                <div className="btn-group">
                    <button className={'btn bg-'+ (ratingYear === 'total'?'dark text-white':'outline-secondary')} onClick={() => setRatingYear('total')}>Общий</button>
                    {rating && rating.years && Object.keys(rating.years).map(el =>
                        <button className={'btn bg-'+ (ratingYear === el?'dark text-white':'outline-secondary')} onClick={() => setRatingYear(el)}>{el}</button>
                    )}
                </div>
                {ratingYear === 'total' && rating.total &&
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ФИО</th>
                                <th>Сумма</th>
                                <th>Скидка</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rating && rating.total && rating.total.map((el, k) =>
                                <tr onClick={() => navigate('/clients/' + el.client_id)}>
                                    <td>{k + 1}</td>
                                    <td>{el.fio}</td>
                                    <td>{Intl.NumberFormat("ru").format(el.summ)}</td>
                                    <td>{el.discount}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
                {ratingYear !== 'total' && rating.years &&
                    <table className="table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>ФИО</th>
                            <th>Сумма</th>
                            <th>Скидка</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rating && rating.years && rating.years[ratingYear].map((el, k) =>
                            <tr onClick={() => navigate('/clients/' + el.client_id)}>
                                <td>{k + 1}</td>
                                <td>{el.fio}</td>
                                <td>{Intl.NumberFormat("ru").format(el.summ)}</td>
                                <td>{el.discount}</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                }
            </Modal>
        </div>
    );
};

export default Clients;