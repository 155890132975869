import axios from "axios"

export default class PaymentService {
    static async getToday() {
        return (await axios.get('payments')).data
    }
    static async newPayment(payment: {}) {
        return (await axios.post('payments', payment)).data
    }
    static async editPayment(id: number, payment: {}) {
        return (await axios.post('payments/' + id, payment)).data
    }
}