import React, {useEffect, useState} from 'react';
import WarehouseRAPI from "../API/WarehouseRAPI";
import {toast} from "react-toastify";
import Modal from "../components/UI/Modal";
import {useTitle} from "../hooks/useTitle";

const WarehouseMain = () => {

    useTitle('Склад')
    const warehouses = [
        {id: 4, name: 'Чернышевского'},
        {id: 5, name: 'Основной склад'},
        {id: 3, name: 'Расходники ремонт'},
        {id: 6, name: 'Материалы'},
    ]

    const [WH, SetWH] = useState(4)
    const [Search, SetSearch] = useState('')
    const [Items, SetItems] = useState([])

    async function printPrice(barcode, type = 'default') {

        let res = ''

        if (type === 'inner') {
            res = await WarehouseRAPI.PrintPriceInner(barcode)
        } else {
            res = await WarehouseRAPI.PrintPrice(barcode, 1)
        }

        let socket = new WebSocket("ws://127.0.0.1:8765");

        setTimeout(function () {
            toast.success('Данные отправлены на печать')
            socket.send(res);
        }, 500);
    }

    const EmptyMoveItem = {
        barcode: '',
        whFrom: WH,
        whTo: '',
        qty: 1,
        price: 0,
        name: ''
    }
    const [MoveItem, SetMoveItem] = useState(EmptyMoveItem)
    const [MoveModal, SetMoveModal] = useState(false)

    async function moveItem() {
        try {
            if (MoveItem.whTo === '') throw Error('Выберите склад назначения')
            if (MoveItem.qty <= 0) throw Error('Введите кол-во')

            const res = await WarehouseRAPI.moveItem(MoveItem)
            if (res.error) {
                throw Error(res.error)
            }

            SetMoveModal(false)
            SetMoveItem(EmptyMoveItem)
            toast.success('Перемещение успешно создано')
        } catch (error) {
            toast.error(error.message)
        }
    }

    async function getItems() {
        SetItems(await WarehouseRAPI.GetItems(WH))
    }

    useEffect(function () {
        getItems()
        SetMoveItem({...MoveItem, whFrom: WH})
    }, [WH])


    const [helpModal, setHelpModal] = useState(false)

    return (
        <div>
            <div className="card">

                <div className="card-header pb-0">
                    <div className="d-lg-flex justify-content-between">
                        <div>
                            <h5 className="mb-0">Остатки</h5>
                            <p className="text-sm mb-0">
                                Страница просмотра остатков, и пермещения по складам
                            </p>
                        </div>
                        <div className="btn bg-gradient-info" onClick={() => setHelpModal(true)}>
                            <i class="fas fa-info"/>
                        </div>
                    </div>
                </div>
                <div className="card-body px-0 pb-0">
                    <div className="table-responsive">
                        <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                            <div className="dataTable-top">
                                <div className="dataTable-search">
                                    <input className="dataTable-input" placeholder="Поиск..." type="text" value={Search} onChange={e => SetSearch(e.target.value.toLowerCase())}/>
                                </div>
                                <div className="dataTable-dropdown float-end">
                                    <label>
                                        <select className="dataTable-selector" value={WH} onChange={(e) => SetWH(e.target.value)}>
                                            {warehouses.map(el =>
                                                <option value={el.id}>{el.name}</option>
                                            )}
                                        </select>
                                    </label>
                                </div>
                            </div>
                            <div className="dataTable-container">
                                <table className="table table-flush dataTable-table" id="products-list">
                                    <thead className="thead-light">
                                    <tr>
                                        <th>Фото</th>
                                        <th>Наимнование</th>
                                        <th>Артикул</th>
                                        <th>Штрихкод</th>
                                        <th style={{width: '100px'}}>Остаток</th>
                                        <th style={{width: '100px'}}>Покупка</th>
                                        <th style={{width: '100px'}}>Продажа</th>
                                        <th>Действия</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Items.filter((f) => f.name.toLowerCase().includes(Search) || f.sku.toLowerCase().includes(Search) || f.barcode.includes(Search)).map((el) =>
                                    <tr>
                                        <td style={{width: '60px'}}>
                                            {el.img &&
                                            <img style={{width: '60px'}} alt={el.barcode} src={el.img}/>
                                            }
                                        </td>
                                        <td className="text-sm" style={{whiteSpace: 'break-spaces'}}>
                                            <h6 className="ms-3 my-auto">{el.name}</h6>
                                        </td>
                                        <td className="text-sm">{el.sku}</td>
                                        <td className="text-sm">{el.barcode}</td>
                                        <td className="text-sm text-bold">{Intl.NumberFormat("ru").format(el.qty)}</td>
                                        <td className="text-sm">{Intl.NumberFormat("ru").format(el.buy_price)}</td>
                                        <td className="text-sm">{Intl.NumberFormat("ru").format(el.price)}</td>
                                        <td style={{display: 'grid', gap: '7px', width: 'fit-content'}}>
                                            <div>
                                                <span onClick={() => printPrice(el.barcode)} className="btn btn-xs btn-outline-secondary py-1 mb-0" style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}>Ценник</span>
                                                <span onClick={() => printPrice(el.barcode, 'inner')} className="btn btn-xs btn-outline-secondary py-1 mb-0" style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}>Ценник внутренний</span>
                                            </div>
                                            <span className="btn btn-xs btn-outline-dark py-1 mb-0" onClick={() => {
                                                SetMoveItem({...MoveItem, barcode: el.barcode, name: el.name})
                                                SetMoveModal(true)
                                            }}>Перемещение</span>
                                        </td>
                                    </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal id="helpModal" title="Помощь по разделу" onClose={() => setHelpModal(false)} show={helpModal} addClass="modal-lg">
                <h5>Перемещение товара</h5>
                <p>
                    Основные материалы находятся на складе "Материалы", локальный склад "Чернышевского"
                </p>
                <ol>
                    <li>Переходим на склад "Материалы"</li>
                    <li>Находим необходимый товар</li>
                    <li>Нажимаем на кнопку "Перемещение"</li>
                    <li>Выбираем склад перемещения, поле "Куда"</li>
                    <li>Вводим кол-во для перемещения и нажимаем кнопку "Переместить"</li>
                </ol>
            </Modal>
            <Modal title="Перемещение товара" show={MoveModal} onClose={() => SetMoveModal(false)} footerButtons={<button className="btn bg-gradient-success" onClick={moveItem}>Переместить</button>}>
                <div className="form-group">
                    <label>Товар</label>
                    <input type="text" disabled value={MoveItem.name} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Куда</label>
                    <select className="form-control" value={MoveItem.whTo} onChange={(e) => SetMoveItem({...MoveItem, whTo: e.target.value})}>
                        <option value="">Выберите склад для перемещения</option>
                        {warehouses.filter(el => el.id != WH).map(el =>
                            <option value={el.id}>{el.name}</option>
                        )}
                    </select>
                </div>
                <div className="form-group">
                    <label>Количество</label>
                    <input type="number" value={MoveItem.qty} onChange={(e) => SetMoveItem({...MoveItem, qty: e.target.value})} className="form-control"/>
                </div>
                <div className="form-group">
                    <label>Цена</label>
                    <input type="number" value={MoveItem.price} onChange={(e) => SetMoveItem({...MoveItem, price: e.target.value})} className="form-control"/>
                </div>
            </Modal>
        </div>
    );
};

export default WarehouseMain;